import MinesweeperPage from './pages/minesweeper';

const App = () => {
  return (
    <>
      <MinesweeperPage />
    </>
  );
};

export default App;
